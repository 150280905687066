import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 style={{
      fontSize: 24
    }}>Wanna contribute on open source projects with me?</h3>
    <ol style={{
      padding: 0,
      margin: 0
    }}>
  <li>- <a style={{
          fontSize: 18
        }} href="https://github.com/sayjeyhi/30Day-30pwa" target="_blank">30 week, 30 PWA</a></li>
  <li>- <a style={{
          fontSize: 18
        }} href="https://github.com/Mariotek/reactjs-persian-interview-questions" target="_blank">500 reactjs persian interview questions</a></li>
    </ol>
    <br />
    <h4>My social networks:</h4>
    <a style={{
      fontSize: 14
    }} href="https://github.com/sayjeyhi" target="_blank">Github</a> - <a style={{
      fontSize: 14
    }} href="https://stackoverflow.com/users/4718253/sayjeyhi" target="_blank">StackOverflow</a> - <a style={{
      fontSize: 14
    }} href="https://www.linkedin.com/in/jafar-rezaei/" target="_blank">LinkedIn</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      